import axios from 'axios';

import {
  REMOTE_APP_CONFIG_ENDPOINT,
  getEnvironment,
} from '../constants';

export default async () => {
  const response = await axios.get(REMOTE_APP_CONFIG_ENDPOINT, {
    headers: {
      'x-sixamhealth-referer': '8WhFwkKB69M7EhE',
    },
  });

  const { data } = response;
  global.remoteAppConfig = data;

  global.API_URL = data[getEnvironment()].server;

  return data;
};
