export const ActionTypes = {
  ADD_TO_SHOPPING_CART: 'ADD_TO_SHOPPING_CART',
  CLEAR_SHOPPING_CART: 'CLEAR_SHOPPING_CART',
  SUBTRACT_ITEM_FROM_CART: 'SUBTRACT_ITEM_FROM_CART',
};

// add an item to the shopping cart
const addToShoppingCart = (item) => {
  return {
    type: ActionTypes.ADD_TO_SHOPPING_CART,
    payload: item,
  };
};

const clearShoppingCart = () => {
  return {
    type: ActionTypes.CLEAR_SHOPPING_CART,
    payload: new Map(),
  };
};

const subtractItemFromCart = (item) => {
  return {
    type: ActionTypes.SUBTRACT_ITEM_FROM_CART,
    payload: item,
  };
};

export { addToShoppingCart, clearShoppingCart, subtractItemFromCart };
