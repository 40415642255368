import axios from 'axios';

const SUB_ROUTE = 'products';

const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/${SUB_ROUTE}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProductsForCompany = (companyID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/${SUB_ROUTE}/company/${companyID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProductsForPrivateFridge = (fridgeID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/${SUB_ROUTE}/fridge/${fridgeID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProduct = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/${SUB_ROUTE}/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllProductTags = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/tags`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export {
  getAllProducts,
  getProduct,
  getProductsForCompany,
  getProductsForPrivateFridge,
  getAllProductTags,
};
