import getRemoteAppConfig from '../../services/app-requests';

// keys for actiontypes
const ActionTypes = {
  SET_REMOTE_APP_CONFIG: 'SET_REMOTE_APP_CONFIG',
};

const fetchRemoteAppConfig = () => {
  return dispatch => new Promise((resolve, reject) => {
    getRemoteAppConfig()
      .then((response) => {
        dispatch({ type: ActionTypes.SET_REMOTE_APP_CONFIG, payload: response });
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export {
  ActionTypes,
  fetchRemoteAppConfig,
};
