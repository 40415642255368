import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import './nav-bar.scss';

import { clearUserData } from '../state/actions';

const logo = require('../assets/images/6amhealth_logo_white.png');

const NavBar = (props) => {
  const nameInfo = (
    <div id="nav-name-info">
      {Object.keys(props.user).length > 0 && props.user.first_name ? <p>{`Welcome ${props.user.first_name}!`}</p> : null}
      <NavLink to={props.location.pathname === '/' ? '/cart' : `${props.location.pathname}/cart`}>
        <ShoppingCartOutlinedIcon id="shopping-cart-icon" />
      </NavLink>
      {props.numItemsInShoppingCart > 0 ? <div id="cart-number"><span>{props.numItemsInShoppingCart}</span></div> : null}
      <NavLink to={Object.keys(props.user).length > 0 ? '/account' : '/login'}>
        <div id="nav-button">
          {Object.keys(props.user).length > 0 ? 'Account' : 'Sign In'}
        </div>
      </NavLink>
    </div>
  );

  return (
    <nav>
      <div id="nav-content">
        <img rel="preload" src={logo} alt="logo" onClick={() => { props.history.push('/'); }} />
        <div id="nav-links">
          <NavLink to="/">Menu</NavLink>
          {Object.keys(props.user).length > 0 ? (
            <NavLink to="/account/orders">Orders</NavLink>
          ) : null}
          {Object.keys(props.user).length > 0 ? (
            <NavLink to="/account/add-fridge">Add a Fridge</NavLink>
          ) : null}
          <NavLink to="/how-it-works">How It Works</NavLink>
        </div>
      </div>
      {nameInfo}
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    numItemsInShoppingCart: state.cart.numItemsInShoppingCart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUserData: () => {
      dispatch(clearUserData());
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar));
