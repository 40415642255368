import * as userRequests from '../../services/user-requests';
import * as orderRequests from '../../services/order-requests';
import * as cartActions from './cart-actions';

const ActionTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',

  // the following actions are booleans indicating if a request was sent for each object but not yet received
  SET_USER_DATA_IN_PROGRESS: 'SET_USER_DATA_IN_PROGRESS',

  // flag to handle any errors that arise
  API_ERROR: 'API_ERROR',
};

// make an asyncronous request to the server to get the user object
const getUserData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
    userRequests
      .getUserData()
      .then((response) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const createUser = (fields) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
    userRequests
      .createUser(fields)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

// make an asyncronous request to the server to update user fields, then get the user data
const updateUserData = (fields) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
    userRequests
      .updateUserData(fields)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const clearUserData = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_USER_DATA, payload: {} });
  };
};

const addPaymentSource = (stripeToken) => {
  return (dispatch) => {
    userRequests
      .addPaymentSource(stripeToken)
      .then(() => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
          });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const deletePaymentSource = (stripeToken) => {
  return (dispatch) => {
    userRequests
      .deletePaymentSource(stripeToken)
      .then(() => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
          });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const createUserOrder = (fields, success, failure) => {
  return (dispatch) => {
    orderRequests
      .createOrder(fields)
      .then((orderResponse) => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
            // clear cart
            dispatch({
              type: cartActions.ActionTypes.CLEAR_SHOPPING_CART,
              payload: {},
            });

            success(orderResponse._id);
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
          });
      })
      .catch((error) => {
        failure(error);
      });
  };
};

const requestNewCompany = (email) => {
  return (dispatch) => {
    userRequests
      .requestNewCompany(email)
      .then(() => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const sendPrivateCompanyCode = (code, successCallback, errorCallback) => {
  return (dispatch) => {
    userRequests
      .sendPrivateCompanyCode(code)
      .then(() => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
            successCallback();
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
            errorCallback(error);
          });
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
};

const requestNewFridge = (pin, successCallback, errorCallback) => {
  return (dispatch) => {
    userRequests
      .requestNewFridge(pin)
      .then(() => {
        dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: true });
        userRequests
          .getUserData()
          .then((response) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.SET_USER_DATA, payload: response });
            successCallback();
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.SET_USER_DATA_IN_PROGRESS, payload: false });
            dispatch({ type: ActionTypes.API_ERROR, payload: error });
            errorCallback(error);
          });
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
};

export {
  ActionTypes,
  getUserData,
  createUser,
  updateUserData,
  clearUserData,
  addPaymentSource,
  deletePaymentSource,
  createUserOrder,
  requestNewCompany,
  sendPrivateCompanyCode,
  requestNewFridge,
};
