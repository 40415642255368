import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import Cart from '../cart';
import './style.scss';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class HowItWorks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  closeCart = () => {
    this.props.history.push('/how-it-works');
  }

  render() {
    const isCartVisible = this.props.location.pathname === '/how-it-works/cart';

    return (
      <div id="how-it-works">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="how-it-works-content">
          <div id="info-panel">
            <div className="content-area">
              <h3>Order Ahead Fridges</h3>
              <p>If you&apos;re a verified employee, you can order ahead at one of your company&apos;s private fridges.</p>
            </div>

            <div className="content-area">
              <h3>Accessing Fridges</h3>
              <p>You can add fridges to your account by entering a work email address and providing the four-digit code that is sent to your email,
              or by going up to a fridge and entering the code listed on front of it.
              </p>

              {Object.keys(this.props.user).length > 0 ? (
                <NavLink to="/account/add-fridge">
                  <div className="button">
                    Add Fridge
                  </div>
                </NavLink>
              ) : null}

            </div>

            <div className="content-area">
              <h3>Order Ahead</h3>
              <p>You can browse the menu of a private fridge and place an order on this website (or on our mobile app). We&apos;ll estimate the date
              your order will be delivered to your fridge.
              </p>
            </div>

            <div className="content-area">
              <h3>Pick Up</h3>
              <p>You&apos;ll get an email when your order has been delivered. Just go to your fridge, pick up your food, and enjoy.
              </p>
            </div>

            <div className="content-area">
              <h3>Pre-Stocked Fridges</h3>
              <p>We have a few fridges around the Greater Boston Area that are pre-stocked. You can&apos;t order ahead at these locations. Instead, go up to them
              and order your food right away!
              </p>
            </div>
          </div>
          <iframe
            title="about-6am-video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jF8qoCB1iQs"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null,
)(HowItWorks));
