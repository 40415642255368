/* eslint-disable global-require */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import Cart from '../cart';
import Receipt from './receipt';
import '../fridges/style.scss';

const fallbackImage = require('../../assets/images/fallback-product-image.png');
const checkmarkImage = require('../../assets/icons/green-checkmark.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const OrderSummary = (props) => {
  if (!props.userData || !props.userData.active_orders) {
    return null;
  }

  const closeCart = () => {
    if (props.match.params.id && props.match.params.id !== 'cart') {
      props.history.push(`/account/orders/summary/${props.match.params.id}`);
    } else {
      props.history.push('/account/orders');
    }
  };

  const getDeliveryText = (dateString) => {
    const date = new Date(dateString);
    return `Pickup on ${date.getMonth()}/${date.getDate()}`;
  };

  const getDateText = (date) => {
    const fullDate = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return `${days[fullDate.getUTCDay()]}, ${months[fullDate.getUTCMonth()]} ${fullDate.getUTCDate()}`;
  };

  const isCartVisible = props.location.pathname.endsWith('cart');
  const [infoContainerVisible, setInfoContainerVisible] = useState(false);

  if (!props.match.params.id) {
    return null;
  }

  const order = props.userData.active_orders.concat(props.userData.closed_orders).find(ord => ord.id === props.match.params.id);
  const fridge = props.fridges.find(frid => frid.id === order.fridge_id);

  return (
    <div id="order-summary">
      <Cart
        isOpen={isCartVisible}
        closeSlideout={closeCart}
      />

      <Transition in={isCartVisible} timeout={duration}>
        {state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          >
            <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={closeCart} />
          </div>
        )}
      </Transition>

      <div id="order-summary-content">
        <div id="top-name-area">
          <img src={checkmarkImage} alt="green-check-mark" />
          <div>
            <h2>Thanks {props.userData.first_name}!</h2>
            <p>Your order has been placed.</p>
          </div>
        </div>
        <div id="content-container">
          <div id="pick-up-area">
            <h3>Pick up at:</h3>
            <div id="fridge-container">
              <div id="fridges">
                <div className="fridge-container fridge-selected">
                  <div className="name-area">
                    <img src={`https://drive.google.com/uc?id=${fridge.image_id}`}
                      alt={fridge.name}
                      onError={(e) => {
                        e.target.src = fallbackImage;
                      }}
                    />
                    <p className="fridge-name">{fridge.name}</p>
                  </div>
                  <div className="info-container">
                    <p className="fridge-location-general">{fridge.location_general}</p>
                    <p className="fridge-location-specific">{fridge.location_specific}</p>
                    <p className="fridge-specific-instructions">{fridge.specific_instructions}</p>
                    <div className="fridge-delivery-area">
                      <img src={require('../../assets/images/question-mark.png')}
                        alt="question-mark"
                        onClick={(e) => {
                          e.stopPropagation();
                          setInfoContainerVisible(!infoContainerVisible);
                        }}
                      />
                      <p className="fridge-delivery-date">{getDeliveryText(order.scheduled_delivery_date)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Transition in={infoContainerVisible} timeout={duration}>
                  {state => (
                    <div style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                    >
                      <div id="fridge-info-container">
                        <h4>When do I get my food?</h4>
                        <p>We deliver every Monday morning. If you order by Thursday at 5pm, we&apos;ll deliver as early as the next Monday.
                          Otherwise, the Monday after that will be the first available delivery date.
                        </p>
                      </div>
                    </div>
                  )}
                </Transition>
              </div>
            </div>
            <div id="info-text-section">
              <p>Look for a product with your name on it at the fridge!</p>
              <p>Please pick up your order on <span>{getDateText(order.scheduled_delivery_date)}</span>.</p>
            </div>
          </div>
          <div id="order-summary-area">
            <h3>Order Summary</h3>
            <Receipt order_id={order.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
    fridges: state.fridges.fridges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createFridge: (fields) => {
    //   dispatch(createFridge(fields));
    // },
    // updateFridge: (fields) => {
    //   dispatch(updateFridge(fields));
    // },
    // deleteFridge: (id) => {
    //   dispatch(deleteFridge(id));
    // },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderSummary));
