import { ActionTypes } from '../actions';

const initialState = {
  userData: {},
  setUserDataInProgress: false,
};

const UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_DATA:
      return { ...state, userData: action.payload };

    case ActionTypes.SET_USER_DATA_IN_PROGRESS:
      return { ...state, setUserDataInProgress: action.payload };

    case ActionTypes.CLEAR_USER_DATA:
      return { ...state, userData: {} };

    default:
      return state;
  }
};

export default UserInfoReducer;
