import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import * as firebase from 'firebase';
import { Transition } from 'react-transition-group';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoadingOverlay from 'react-loading-overlay';
import { TEST_STRIPE_KEY, PROD_STRIPE_KEY } from '../../constants';

import Cart from '../cart';
import Fridges from '../fridges';
import AddCard from './add-card';
import './style.scss';

import {
  addToShoppingCart,
  clearShoppingCart,
  createUserOrder,
  subtractItemFromCart,
} from '../../state/actions';

import { getDeliveryDate } from '../../services/order-requests';

const fallbackImage = require('../../assets/images/fallback-product-image.png');
const plusImage = require('../../assets/images/plus.png');
const minusImage = require('../../assets/images/minus.png');
const questionMark = require('../../assets/images/question-mark.png');

// import card images
const visa = require('../../assets/icons/cards/visa.png');
const mastercard = require('../../assets/icons/cards/mastercard.png');
const unionpay = require('../../assets/icons/cards/union-pay.png');
const amex = require('../../assets/icons/cards/amex.png');
const discover = require('../../assets/icons/cards/discover.png');
const dinersclub = require('../../assets/icons/cards/diners-club.png');
const jcb = require('../../assets/icons/cards/jcb.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

let stripePromise = loadStripe(PROD_STRIPE_KEY);

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: {},
      possibleDeliveryDates: [],
      chosenDeliveryDate: null,
      infoContainerVisible: false,
      loadingContainerVisible: false,
      stripeSetup: false,
    };
  }

  componentDidMount() {
    getDeliveryDate()
      .then((date) => {
        this.setState({
          chosenDeliveryDate: date[0],
          possibleDeliveryDates: date,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  closeCart = () => {
    this.props.history.push('/checkout');
  }

  renderDeliveryText = () => {
    if (this.state.chosenDeliveryDate) {
      const date = new Date(this.state.chosenDeliveryDate);
      return `Pickup on ${date.getMonth()}/${date.getDate()}`;
    } else {
      return '';
    }
  }

  renderProduct = (product) => {
    return (
      <div className="product"
        onClick={() => {
          this.props.history.push(`/product/${product.id}`);
        }}
      >
        <img src={`https://drive.google.com/uc?id=${product.image_id}`}
          alt={product.name}
          onError={(e) => {
            e.target.src = fallbackImage;
          }}
        />
        <div className="text-area">
          <p>{product.name}</p>
          <p>{`$${product.price.toFixed(2)}`}</p>
        </div>
      </div>
    );
  }

  renderSelectedFridge = () => {
    return (
      <div>
        <h3>Select a Fridge</h3>
        <Fridges infoContainerVisible={this.state.infoContainerVisible} />
      </div>
    );
  }

  renderTrendingItems = () => {
    if (Object.keys(this.props.selectedFridge).length !== 0) {
      const trending = [];

      this.props.trendingItems.forEach((id) => {
        const product = this.props.products.find(prod => prod.id === id);

        if (product && product.visible && !Object.keys(this.props.cart.toJSON()).includes(product.id)) {
          trending.push(
            <div className="trending-item">
              <img
                className="cart-item-product-image"
                src={`https://drive.google.com/uc?id=${product.image_id}`}
                alt={product.name}
                onError={(e) => {
                  e.target.src = fallbackImage;
                }}
              />
              <p>{product.name}</p>
              <img className="plus-icon" src={plusImage} alt="plus" onClick={() => this.props.addToShoppingCart(product)} />
            </div>,
          );
        }
      });

      return (
        <div id="trending-items-container">
          <h3>Trending items at <span>{this.props.selectedFridge.name}</span></h3>
          <div id="trending-items-area">{trending}</div>
        </div>
      );
    }

    return null;
  }

  getDateText = (date) => {
    const fullDate = new Date(date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return `${days[fullDate.getUTCDay()]}, ${months[fullDate.getUTCMonth()]} ${fullDate.getUTCDate()}`;
  }

  renderPickupDate = () => {
    return (
      <div>
        <h3>Pick up</h3>
        <div id="pickup-container">
          <RadioGroup aria-label="delivery-date"
            name="delivery-date"
            value={this.state.chosenDeliveryDate}
            onChange={(e) => {
              this.setState({
                chosenDeliveryDate: e.target.value,
              });
            }}
          >
            {this.state.possibleDeliveryDates.map((date, i) => {
              return (
                <div>
                  <FormControlLabel value={date} control={<Radio color="primary" />} label={this.getDateText(date)} />
                  {i < this.state.possibleDeliveryDates.length - 1 ? <div className="thick-grey-line" /> : null}
                </div>
              );
            })}
          </RadioGroup>
        </div>
      </div>

    );
  }

  renderCurrentPaymentMethods = () => {
    if (Object.keys(this.state.paymentMethod).length === 0 && this.props.userData && this.props.userData.payment_info
    && this.props.userData.payment_info.sources && this.props.userData.payment_info.sources.data.length === 1) {
      this.setState({
        paymentMethod: this.props.userData.payment_info.sources.data[0],
      });
    }

    if (Object.keys(this.props.userData).length > 0) {
      if (this.props.userData.payment_info) {
        if (Object.keys(this.props.userData.payment_info).length > 0) {
          const paymentInfo = this.props.userData.payment_info;

          if (paymentInfo.sources.total_count > 0) {
            return (
              <div id="payment-info-area">
                {paymentInfo.sources.data.map((source) => {
                  const imgSrc = this.getCardImage(source.brand);

                  return (
                    <div className="payment-info"
                      onClick={() => { this.setState({ paymentMethod: source }); }}
                      id={this.state.paymentMethod.id === source.id ? 'selected-card' : ''}
                    >
                      <img src={imgSrc}
                        alt={source.brand}
                        onError={(e) => {
                          e.target.src = fallbackImage;
                        }}
                      />
                      <p>
                        <span>&#183;&#183;&#183;&#183;</span>
                        <span>&#183;&#183;&#183;&#183;</span>
                        <span>&#183;&#183;&#183;&#183;</span>
                        {source.last4}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          }
        }
      }
    }

    return null;
  }

  renderPaymentMethod = () => {
    return (
      <div>
        <h3>Payment</h3>
        <div id="payment-method-container">
          <h4>My Cards</h4>
          {this.renderCurrentPaymentMethods()}
          <div className="thick-grey-line" />
          {this.state.stripeSetup ? (
            <Elements stripe={stripePromise}>
              <AddCard />
            </Elements>
          ) : null}
        </div>
      </div>

    );
  }

  getCardImage = (brand) => {
    switch (brand) {
      case 'Visa':
        return visa;
      case 'MasterCard':
        return mastercard;
      case 'American Express':
        return amex;
      case 'Discover':
        return discover;
      case 'Diners Club':
        return dinersclub;
      case 'JCB':
        return jcb;
      case 'UnionPay':
        return unionpay;
      default:
        return null;
    }
  }

  renderCartItems = () => {
    return (
      <div id="cart-items-container">
        {Object.keys(this.props.cart.toJSON()).map((id) => {
          const product = this.props.cart.toJSON()[id];

          return (
            <div className="cart-item">
              <img
                className="cart-item-product-image"
                src={`https://drive.google.com/uc?id=${product.image_id}`}
                alt={product.name}
                onError={(e) => {
                  e.target.src = fallbackImage;
                }}
              />

              <div className="cart-item-info-area">
                <h4>{product.name}</h4>
                <div className="plus-minus-region">
                  <img src={plusImage} alt="plus" onClick={() => this.props.addToShoppingCart(product)} />
                  <p>{product.numInCart}</p>
                  <img src={minusImage} alt="plus" onClick={() => this.props.subtractItemFromCart(product)} />
                </div>
              </div>

              <div className="price-area">
                <h4>{product.price}</h4>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderOrderSummary = () => {
    const taxRate = this.props.selectedFridge.tax_rate || 0.0625;

    return (
      <div id="order-summary-container">
        <h3>Order Summary</h3>
        {Object.keys(this.props.cart.toJSON()).length > 0 ? this.renderCartItems() : (
          <p id="empty-cart-text">There&apos;s nothing in your cart!</p>
        )}
        <div id="bottom-area">
          <div id="cart-line" />
          <div id="delivery-date-area">
            <img src={questionMark}
              alt="question-mark"
              onClick={(e) => {
                e.stopPropagation();
                this.setState(prevState => ({
                  infoContainerVisible: !prevState.infoContainerVisible,
                }));
              }}
            />
            <p id="fridge-delivery-date">{this.renderDeliveryText()}</p>
          </div>
          <div id="cart-line" />
          <div id="price-region">
            <p>Subtotal: <span className="horizontal-space" />${this.props.cartSubtotal.toFixed(2)}</p>
            <p>Estimated Tax: <span className="horizontal-space" /> ${(this.props.cartSubtotal * taxRate).toFixed(2)}</p>
            <h4>Total: ${(this.props.cartSubtotal * (1 + taxRate)).toFixed(2)}</h4>
          </div>
        </div>
      </div>
    );
  }

  renderPlaceOrderButton = () => {
    const disabled = !(this.props.numItemsInShoppingCart > 0 && Object.keys(this.state.paymentMethod).length > 0 && Object.keys(this.props.selectedFridge).length > 0);

    return (
      <div id="checkout-button-region">
        <NavLink to="/">
          <div id="menu-button">
            <ArrowBackIosIcon id="arrow" />
            <span>Return to Menu</span>
          </div>
        </NavLink>

        <div id="place-order-button-container" onClick={this.createUserPrivateOrder}>
          <p className={disabled ? 'disabled' : ''}>Place Order</p>
        </div>
      </div>
    );
  }

  // generates and places an order for a logged in user
  createUserPrivateOrder = () => {
    // ensure something to purchase
    if (this.props.numItemsInShoppingCart === 0 || this.props.numItemsInShoppingCart === '0') {
      toast.error('Please add items to your cart before placing an order');
    } else if (Object.keys(this.props.selectedFridge).length === 0) {
      toast.error('Please select a fridge');
    } else if (Object.keys(this.state.paymentMethod).length === 0) {
      toast.error('Please select a payment method');
    } else {
      // grab items to buy and amount of each
      const items = {};
      this.props.cart.entrySeq().forEach(([id, obj]) => {
        items[id] = obj.numInCart;
      });

      // set payment fields for server
      const fields = {
        fridge_id: this.props.selectedFridge.id,
        user_id: firebase.auth().currentUser.uid,
        items,
        subtotal: this.props.cartSubtotal,
        total: (this.props.cartSubtotal * (1 + this.props.selectedFridge.tax_rate)).toFixed(2),
        payment_method: `${this.state.paymentMethod.brand} ending in ${this.state.paymentMethod.last4}`,
        payment_source: this.state.paymentMethod.id,
        customer_id: this.props.userData.stripe_id,
        scheduled_delivery_date: this.state.chosenDeliveryDate,
        special_instructions: '',
      };

      this.setState({
        loadingContainerVisible: true,
      }, () => {
        // create an order then grab user data through redux
        this.props.createUserOrder(
          fields,

          // success callback
          (orderNumber) => {
            setTimeout(() => {
              this.setState({
                loadingContainerVisible: false,
              });

              this.props.clearShoppingCart();
              this.props.history.push(`/account/orders/summary/${orderNumber}`);
            }, 1500);
          },
          // failure callback
          (error) => {
            this.setState({
              loadingContainerVisible: false,
            });

            // handle case in which some items were not available for purchase
            if (error.status) {
              toast.error('Some items weren\'t available. Please try again.');
            } else {
              toast.error('There was an issue placing your order. Please contact us for more information.');
            }
          },
        );
      });
    }
  };

  render() {
    if (Object.keys(this.props.userData).length > 0 && !this.state.stripeSetup) {
      stripePromise = loadStripe(this.props.userData.stripe_test_mode ? TEST_STRIPE_KEY : PROD_STRIPE_KEY);

      this.setState({
        stripeSetup: true,
      });
    }

    const isCartVisible = this.props.location.pathname === '/checkout/cart';

    return (
      <div id="checkout">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <LoadingOverlay
          active={this.state.loadingContainerVisible}
          spinner
          text="Placing your order"
          className={this.state.loadingContainerVisible ? 'loading-overlay' : ''}
        />

        <div id="checkout-content">
          <h2>Express Checkout</h2>
          <div id="checkout-content-container">
            <div id="left">
              {this.renderSelectedFridge()}
              {this.renderTrendingItems()}
              {this.renderPickupDate()}
              {this.renderPaymentMethod()}
            </div>
            <div id="right">
              {this.renderOrderSummary()}
              {this.renderPlaceOrderButton()}
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.shoppingCart,
    selectedFridge: state.fridges.selectedFridge,
    trendingItems: state.fridges.trendingItems,
    products: state.products.products,
    numItemsInShoppingCart: state.cart.numItemsInShoppingCart,
    cartSubtotal: state.cart.cartSubtotal,
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToShoppingCart: (product) => {
      dispatch(addToShoppingCart(product));
    },
    subtractItemFromCart: (product) => {
      dispatch(subtractItemFromCart(product));
    },
    createUserOrder: (fields, success, failure) => {
      dispatch(createUserOrder(fields, success, failure));
    },
    clearShoppingCart: () => {
      dispatch(clearShoppingCart());
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout));
