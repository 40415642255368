import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as firebase from 'firebase';
import { Transition } from 'react-transition-group';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Cart from '../cart';
import AddCard from '../checkout/add-card';

import { TEST_STRIPE_KEY, PROD_STRIPE_KEY } from '../../constants';
import './style.scss';
import '../checkout/style.scss';

import { clearUserData, updateUserData, deletePaymentSource } from '../../state/actions';

let stripePromise = loadStripe(PROD_STRIPE_KEY);

const fallbackImage = require('../../assets/images/fallback-product-image.png');
const redCrossImage = require('../../assets/icons/red-cross.png');

// import card images
const visa = require('../../assets/icons/cards/visa.png');
const mastercard = require('../../assets/icons/cards/mastercard.png');
const unionpay = require('../../assets/icons/cards/union-pay.png');
const amex = require('../../assets/icons/cards/amex.png');
const discover = require('../../assets/icons/cards/discover.png');
const dinersclub = require('../../assets/icons/cards/diners-club.png');
const jcb = require('../../assets/icons/cards/jcb.png');


const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stripeSetup: false,
    };
  }

  closeCart = () => {
    this.props.history.push('/account');
  }

  saveFirstName = (value) => {
    this.props.updateUserData({
      first_name: value,
    });
  }

  saveLastName = (value) => {
    this.props.updateUserData({
      last_name: value,
    });
  }

  savePhoneNumber = (value) => {
    this.props.updateUserData({
      phone_number: value,
    });
  }

  getCardImage = (brand) => {
    switch (brand) {
      case 'Visa':
        return visa;
      case 'MasterCard':
        return mastercard;
      case 'American Express':
        return amex;
      case 'Discover':
        return discover;
      case 'Diners Club':
        return dinersclub;
      case 'JCB':
        return jcb;
      case 'UnionPay':
        return unionpay;
      default:
        return null;
    }
  }

  deleteCard = (source) => {
    this.props.deletePaymentSource(source.id);
  }

  renderCurrentPaymentMethods = () => {
    if (Object.keys(this.props.userData).length > 0) {
      if (this.props.userData.payment_info) {
        if (Object.keys(this.props.userData.payment_info).length > 0) {
          const paymentInfo = this.props.userData.payment_info;

          if (paymentInfo.sources.total_count > 0) {
            return (
              <div id="payment-info-area">
                {paymentInfo.sources.data.map((source) => {
                  const imgSrc = this.getCardImage(source.brand);

                  return (
                    <div className="payment-info">
                      <img src={imgSrc}
                        alt={source.brand}
                        onError={(e) => {
                          e.target.src = fallbackImage;
                        }}
                      />
                      <p>
                        <span>&#183;&#183;&#183;&#183;</span>
                        <span>&#183;&#183;&#183;&#183;</span>
                        <span>&#183;&#183;&#183;&#183;</span>
                        {source.last4}
                      </p>
                      <img src={redCrossImage} alt="red x" id="remove-card" onClick={() => { this.deleteCard(source); }} />
                    </div>
                  );
                })}
              </div>
            );
          }
        }
      }
    }

    return null;
  }

  render() {
    if (Object.keys(this.props.userData).length > 0 && !this.state.stripeSetup) {
      stripePromise = loadStripe(this.props.userData.stripe_test_mode ? TEST_STRIPE_KEY : PROD_STRIPE_KEY);

      this.setState({
        stripeSetup: true,
      });
    }

    const isCartVisible = this.props.location.pathname === '/account/cart';

    return (
      <div id="account">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="account-content">
          <h2>My Account</h2>
          <div id="account-container">
            <div id="info-container" className="account-container">
              <h3>My Details</h3>
              <div className="account-container-wrapper">
                <AccountRow keyText="First Name" valueText={this.props.userData.first_name} editable callback={this.saveFirstName} />
                <div className="account-line" />
                <AccountRow keyText="Last Name" valueText={this.props.userData.last_name} editable callback={this.saveLastName} />
                <div className="account-line" />
                <AccountRow keyText="Email" valueText={this.props.userData.email} />
                <div className="account-line" />
                <AccountRow keyText="Birthday" valueText={this.props.userData.birthday ? new Date(this.props.userData.birthday).toDateString() : ''} />
                <div className="account-line" />
                <AccountRow keyText="Phone" valueText={this.props.userData.phone_number} editable callback={this.savePhoneNumber} />
              </div>
              <h3>My Fridges</h3>
              <div id="fridge-list-container">
                {this.props.fridges.map((fridge) => {
                  return (
                    <div className="account-fridge-container">
                      <div className="name-area">
                        <img src={`https://drive.google.com/uc?id=${fridge.image_id}`}
                          alt={fridge.name}
                          onError={(e) => {
                            e.target.src = fallbackImage;
                          }}
                        />
                        <p className="fridge-name">{fridge.name}</p>
                      </div>
                      <div className="info-container">
                        <p className="fridge-location-general">{fridge.location_general}</p>
                        <p className="fridge-location-specific">{fridge.location_specific}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div id="payment-container" className="account-container">
                <h3>Payment</h3>
                <div className="account-container-wrapper">
                  <div id="payment-method-container" className="account-payment-method">
                    <h4>My Cards</h4>
                    {this.renderCurrentPaymentMethods()}
                    <div className="thick-grey-line" />
                    {this.state.stripeSetup ? (
                      <Elements stripe={stripePromise}>
                        <AddCard />
                      </Elements>
                    ) : null}
                  </div>
                </div>
              </div>
              <p id="logout-button"
                onClick={() => {
                  localStorage.clear();
                  this.props.clearUserData();
                  firebase.auth().signOut();
                  this.props.history.push('/');
                }}
              >Logout
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AccountRow = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(props.valueText);

  return (
    <div className="account-row">
      <div className="account-row-key-container">
        <p className="key">{props.keyText}</p>
        {isEditing
          ? <input value={editValue} onChange={e => setEditValue(e.target.value)} />
          : <p className="value">{props.valueText}</p>}
      </div>
      {props.editable ? (
        <div className="change-button"
          onClick={() => {
            if (isEditing) {
              props.callback(editValue);
            } else {
              setEditValue(props.valueText);
            }

            setIsEditing(!isEditing);
          }}
        >
          <p>{isEditing ? 'Save' : 'Change'}</p>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
    fridges: state.fridges.fridges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUserData: () => {
      dispatch(clearUserData());
    },
    updateUserData: (fields) => {
      dispatch(updateUserData(fields));
    },
    deletePaymentSource: (id) => {
      dispatch(deletePaymentSource(id));
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account));
