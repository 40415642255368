import { combineReducers } from 'redux';

import AppReducer from './app-reducer';
import CartReducer from './cart-reducer';
import FridgeReducer from './fridge-reducer';
import ProductsReducer from './products-reducer';
import PromotionsReducer from './promotions-reducer';
import UserReducer from './user-reducer';

const rootReducer = combineReducers({
  app: AppReducer,
  cart: CartReducer,
  fridges: FridgeReducer,
  products: ProductsReducer,
  promotions: PromotionsReducer,
  user: UserReducer,
});

export default rootReducer;
