import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as firebase from 'firebase';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import reducers from './state/reducers';
import { ActionTypes } from './state/actions';

import App from './app';
import './style.scss';

// set the configuration for the app
const config = {
  apiKey: 'AIzaSyAouxtAu6-lOTYZGY9XZI81mjju5XpRr44',
  authDomain: 'dali-6amhealth.firebaseapp.com',
  databaseURL: 'https://dali-6amhealth.firebaseio.com',
  projectId: 'dali-6amhealth',
  storageBucket: 'dali-6amhealth.appspot.com',
  messagingSenderId: '1039983863584',
};

firebase.initializeApp(config);

// this creates the store with the reducers, and does some other stuff to initialize devtools
// boilerplate to copy, don't have to know
const store = createStore(reducers, {}, compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
));

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');
if (token) {
  store.dispatch({ type: ActionTypes.AUTH_USER, payload: user });
}

const theme = createMuiTheme({
  palette: {
    primaryLight: {
      main: '#B3DAC3',
    },
    primary: {
      main: '#008037',
    },
    primaryDark: {
      main: '#00451E',
    },
    secondary: {
      light: '#D4E5B9',
      main: '#84C225',
    },
  },
  typography: {
    fontFamily: [
      'Avenir-Medium',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

// we now wrap App in a Provider
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('main'),
);
