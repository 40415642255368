import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import * as firebase from 'firebase';
import Cart from '../../cart';
import './style.scss';

import { createUser, getUserData } from '../../../state/actions';
import { userWithEmailExists } from '../../../services/user-requests';

const googleImage = require('../../../assets/images/google.png');
const facebookImage = require('../../../assets/images/facebook-white.png');
const appleImage = require('../../../assets/images/apple.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmedPassword: '',
      moreInfo: false,
      passwordMatch: true,
      emailAlreadyRegistered: false,
      shortPasswordLength: false,
      noFirstName: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      zipcode: '',
    };
  }

  closeCart = () => {
    this.props.history.push('/sign-up');
  }

  signUp = () => {
    if (this.state.password !== this.state.confirmedPassword) {
      this.setState({
        passwordMatch: false,
      });
    } else {
    // check if user with email exists
      userWithEmailExists(this.state.email)
        .then((ans) => {
          if (!ans) {
            this.validateAndProceed();
          } else {
            this.setState({
              emailAlreadyRegistered: true,
            });
          }
        })
        .catch((error) => {
          this.validateAndProceed();
        });
    }
  }

  validateAndProceed = () => {
    if (this.state.password.length < 6) {
      this.setState({
        shortPasswordLength: true,
      });
    } else {
      // bring up loading pop-up
      this.setState({
        moreInfo: true,
      });
    }
  };

  renderErrorArea = () => {
    let errorText = '';

    if (!this.state.passwordMatch) {
      errorText = 'Passwords do not match';
    } else if (this.state.emailAlreadyRegistered) {
      errorText = 'An account with this email already exists';
    } else if (this.state.shortPasswordLength) {
      errorText = 'The password is too short';
    } else if (this.state.noFirstName) {
      errorText = 'Please provide your first name';
    }

    return (
      <div id="error-area">
        <p>{errorText}</p>
      </div>
    );
  }

  createAccount = () => {
    if (this.state.firstName === '') {
      this.setState({
        noFirstName: true,
      });
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then(
          () => {
            const fields = {
              email: this.state.email,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              phone_number: this.state.phoneNumber,
              zip_code: this.state.zipcode,
            };

            // if (this.state.month && this.state.day && this.state.year) {
            //   const month = parseInt(this.state.month, 10);
            //   const day = parseInt(this.state.day, 10);
            //   const year = parseInt(this.state.year, 10);

            //   fields.birthday = new Date(year, month - 1, day);
            // }

            this.props.createUser(fields);
            this.props.history.push('/');
          },
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }

  signInGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  signInFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  signInApple = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const isCartVisible = this.props.location.pathname === '/sign-up/cart';

    return (
      <div id="sign-up">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="welcome-area">
          <h2>Welcome to 6AM Health</h2>
          <p>Already have an account? <span onClick={() => { this.props.history.push('/login'); }}>Click here</span> to sign in.</p>
        </div>

        <div id="top-section">
          {this.state.moreInfo ? (
            <div id="more-info">
              <p id="instructions">We&apos;d like a bit more information before we create your account.</p>

              <div className="input-area">
                <p>First Name <span style={{ color: 'red' }}>*</span></p>
                <input value={this.state.firstName} onChange={e => this.setState({ firstName: e.target.value })} required />
              </div>

              <div className="input-area">
                <p>Last Name</p>
                <input value={this.state.lastName} onChange={e => this.setState({ lastName: e.target.value })} />
              </div>

              <div className="input-area">
                <p>Phone Number</p>
                <input value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} />
              </div>

              <div className="input-area">
                <p>ZIP Code</p>
                <input value={this.state.zipcode} onChange={e => this.setState({ zipcode: e.target.value })} />
              </div>

              {this.renderErrorArea()}

              <div id="create-account-button" onClick={this.createAccount}>
                Create Account
              </div>
            </div>
          ) : (
            <div>
              <div id="sign-up-area">
                <div className="input-area">
                  <p>Email</p>
                  <input value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                </div>
              </div>
              <div id="sign-up-area">
                <div className="input-area">
                  <p>Password</p>
                  <input value={this.state.password} onChange={e => this.setState({ password: e.target.value })} type="password" />
                </div>
              </div>
              <div id="sign-up-area">
                <div className="input-area">
                  <p>Confirm Password</p>
                  <input value={this.state.confirmedPassword} onChange={e => this.setState({ confirmedPassword: e.target.value })} type="password" />
                </div>
              </div>

              {this.renderErrorArea()}

              <div id="sign-up-button" onClick={this.signUp}>
                Sign Up
              </div>

              <div id="line-area">
                <div className="line" />
                <p>OR</p>
                <div className="line" />
              </div>

              <div className="third-party-area" onClick={this.signInGoogle}>
                <img src={googleImage} alt="google-logo" />
                <span>Sign in with Google</span>
              </div>

              <div className="third-party-area" style={{ backgroundColor: '#3B5998' }} onClick={this.signInFacebook}>
                <img src={facebookImage} alt="facebook-logo" />
                <span style={{ color: 'white' }}>Sign in with Facebook</span>
              </div>

              <div className="third-party-area" style={{ backgroundColor: 'black' }} onClick={this.signInApple}>
                <img id="apple-image" src={appleImage} alt="apple-logo" />
                <span style={{ color: 'white' }}>Sign in with Apple</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (fields) => {
      dispatch(createUser(fields));
    },
    getUserData: (id) => {
      dispatch(getUserData(id));
    },
  };
};

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(SignUp));
