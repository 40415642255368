import { ActionTypes } from '../actions';

// initial state
const initialState = {};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REMOTE_APP_CONFIG:
      return action.payload;

    default:
      return state;
  }
};

export default AppReducer;
