import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ItemPopup from './item-popup';
import Cart from '../cart';
import './style.scss';

const fallbackImage = require('../../assets/images/fallback-product-image.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: ['All', 'Bowls', 'Sandwiches', 'Snacks', 'Drinks'],
      categoriesAuth: ['All', 'Favorites', 'Bowls', 'Sandwiches', 'Snacks', 'Drinks'],
      foodCategory: 'All',
      searchValue: '',
      glutenFreeFilter: false,
      veganFilter: false,
      vegetarianFilter: false,
    };
  }

  getItemsToDisplay = () => {
    // grab tab for current category
    const tag = this.props.productTags.filter(
      tagObj => tagObj.name === this.state.foodCategory,
    );

    let products = this.props.products.filter(product => product.visible);

    // filter by search value
    if (this.state.searchValue.length > 0) {
      const searchTerms = this.state.searchValue.split(' ');

      products = products.filter((product) => {
        let match = false;

        searchTerms.forEach((term) => {
          if (product.name.toUpperCase().includes(term.toUpperCase()) || product.ingredients.join(' ').toUpperCase().includes(term.toUpperCase())) {
            match = true;
          }
        });

        return match;
      });
    }

    // filter by gluten free
    if (this.state.glutenFreeFilter) {
      products = products.filter(product => product.gluten_free);
    }

    // filter by vegan
    if (this.state.veganFilter) {
      products = products.filter(product => product.vegan);
    }

    // filter by vegetarian
    if (this.state.vegetarianFilter) {
      products = products.filter(product => product.vegetarian);
    }

    // if on category page, filter
    if (products.length !== undefined && this.state.foodCategory !== '' && this.state.foodCategory !== 'Favorites' && tag.length > 0) {
      return products.filter(
        obj => obj.tags.includes(tag[0].id),
      );
    }

    // handle all category
    if (this.state.foodCategory === '' || this.state.foodCategory === 'All') {
      return products;
    }

    // handle favorites category
    if (this.state.foodCategory === 'Favorites') {
      return products.filter(item => this.props.userData.favorite_item_ids.includes(item.id));
    }

    // base return everything
    return products;
  };

  renderProduct = (product) => {
    return (
      <div className="product"
        onClick={() => {
          this.props.history.push(`/product/${product.id}`);
        }}
      >
        <img rel="preload"
          src={`https://drive.google.com/uc?id=${product.image_id}`}
          alt={product.name}
          onError={(e) => {
            e.target.src = fallbackImage;
          }}
        />
        <div className="text-area">
          <p>{product.name}</p>
          <p>{`$${product.price.toFixed(2)}`}</p>
        </div>
      </div>
    );
  }

  closeCart = () => {
    this.props.history.push('/');
  }

  render() {
    const categories = Object.keys(this.props.userData).length > 0 ? this.state.categoriesAuth : this.state.categories;
    const isOpen = this.props.match.params.id !== null && this.props.match.params.id !== undefined;
    const isCartVisible = this.props.location.pathname === '/cart';

    return (
      <div id="menu">
        <ItemPopup
          isOpen={isOpen}
          product={this.props.products.find(product => product.id === this.props.match.params.id)}
          closeModal={this.closeCart}
        />

        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="menu-container">
          <div id="top-area">
            <h2 onClick={() => { this.setState({ foodCategory: 'All' }); this.props.history.push('/'); }}>Our Menu</h2>
          </div>

          <div id="across-section">
            <div>
              <div id="search-container">
                <input value={this.state.searchValue} onChange={e => this.setState({ searchValue: e.target.value })} />
                <div id="search-button">Search</div>
              </div>
              <div id="toggle-container">
                <div className="item-area" onClick={() => this.setState(prevState => ({ glutenFreeFilter: !prevState.glutenFreeFilter }))}>
                  <input type="checkbox" checked={this.state.glutenFreeFilter} />
                  <p>Gluten Free</p>
                </div>
                <div className="item-area" onClick={() => this.setState(prevState => ({ veganFilter: !prevState.veganFilter }))}>
                  <input type="checkbox" checked={this.state.veganFilter} />
                  <p>Vegan</p>
                </div>
                <div className="item-area" onClick={() => this.setState(prevState => ({ vegetarianFilter: !prevState.vegetarianFilter }))}>
                  <input type="checkbox" checked={this.state.vegetarianFilter} />
                  <p>Vegetarian</p>
                </div>
              </div>
            </div>
            <div id="category-area">
              {categories.map((category) => {
                return (
                  <p id={this.state.foodCategory === category ? 'selected' : ''}
                    onClick={() => {
                      this.setState({
                        foodCategory: category,
                      });
                    }}
                  >{category}
                  </p>
                );
              })}
            </div>
          </div>

          <div id="product-container">
            {this.getItemsToDisplay().map((product) => {
              return this.renderProduct(product);
            })}
          </div>
        </div>

        <NavLink to="/cart">
          <div id="cart-icon-container">
            <ShoppingCartOutlinedIcon id="cart-icon" />
            {this.props.numItemsInShoppingCart > 0 ? <div id="cart-number"><span>{this.props.numItemsInShoppingCart}</span></div> : null}
          </div>
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
    productTags: state.products.productTags,
    userData: state.user.userData,
    numItemsInShoppingCart: state.cart.numItemsInShoppingCart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createFridge: (fields) => {
    //   dispatch(createFridge(fields));
    // },
    // updateFridge: (fields) => {
    //   dispatch(updateFridge(fields));
    // },
    // deleteFridge: (id) => {
    //   dispatch(deleteFridge(id));
    // },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu));
