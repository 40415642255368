import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const fallbackImage = require('../../assets/images/fallback-product-image.png');

// import card images
const visa = require('../../assets/icons/cards/visa.png');
const mastercard = require('../../assets/icons/cards/mastercard.png');
const unionpay = require('../../assets/icons/cards/union-pay.png');
const amex = require('../../assets/icons/cards/amex.png');
const discover = require('../../assets/icons/cards/discover.png');
const dinersclub = require('../../assets/icons/cards/diners-club.png');
const jcb = require('../../assets/icons/cards/jcb.png');

const ReceiptArea = (props) => {
  if (!props.userData || !props.userData.active_orders) {
    return null;
  }

  const parseTime = (info) => {
    const date = new Date(info);
    const parseDate = date.toDateString().split(' ');
    const time = date.toLocaleTimeString().split(' ');
    const times = time[0].split(':');

    // month, day, hour, minute, AM/PM
    return [parseDate[1], parseDate[2], times[0], times[1], time[1]];
  };

  const getCardImage = (brand) => {
    switch (brand) {
      case 'Visa':
        return visa;
      case 'MasterCard':
        return mastercard;
      case 'American Express':
        return amex;
      case 'Discover':
        return discover;
      case 'Diners Club':
        return dinersclub;
      case 'JCB':
        return jcb;
      case 'UnionPay':
        return unionpay;
      default:
        return null;
    }
  };

  const order = props.userData.active_orders.concat(props.userData.closed_orders).find(ord => ord.id === props.order_id);

  if (!order) {
    return null;
  }

  const fridge = props.fridges.find(frid => frid.id === order.fridge_id);
  const date = parseTime(order.time_opened);
  const imgSrc = getCardImage(order.payment_method.split(' ')[0]);
  const endingIn = order.payment_method.split(' ')[3];

  return (
    <div id="order-preview-container">
      <div id="top-order-area">
        <h4>{date[0]}{' '}{date[1]}{' '}{date[2]}:{date[3]}{' '}{date[4]}</h4>
        <h4>{fridge ? fridge.name : ''}</h4>
      </div>
      <div id="order-info-area">
        <div>
          <p>Payment Method</p>
          <div>
            <img src={imgSrc} alt={order.payment_method.split(' ')[0]} />
            <p>{endingIn}</p>
          </div>
        </div>
        <div>
          <p>Order ID</p>
          <p>{order.id.slice(0, 6)}</p>
        </div>
      </div>
      <div className="grey-line" />
      <div id="item-area">
        {Object.keys(order.items).map((itemID) => {
          const item = order.items[itemID];
          return (
            <div className="item-container">
              <img
                src={`https://drive.google.com/uc?id=${item.productInfo.image_id}`}
                alt={item.productInfo.name}
                onError={(e) => {
                  e.target.src = fallbackImage;
                }}
              />
              <div>
                <h4>{item.productInfo.name}</h4>
                <p>Qty: {item.count}</p>
                <p>Price: ${item.productInfo.price.toFixed(2)}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grey-line" />
      <div id="total-area">
        <p>Subtotal: ${order.subtotal.toFixed(2)}</p>
        <p>Tax: ${(order.total - order.subtotal).toFixed(2)}</p>
        <h4>Total: ${order.total.toFixed(2)}</h4>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
    fridges: state.fridges.fridges,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null,
)(ReceiptArea));
