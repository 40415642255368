import axios from 'axios';
import * as firebase from 'firebase';
import fetchRemoteAppConfig from './app-requests';

const SUB_ROUTE = 'orders';

const createOrder = (fields) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/private`;

        axios
          .post(url, {
            authToken: idToken,
            fields,
          })
          .then((response) => {
            if (response.data.status) {
              if (response.data.status === 406 || response.data.status === '406' || response.data.itemsNotAvailable) {
                reject(response.data);
              } else {
                resolve(response.data);
              }
            } else {
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const isValidOrder = (fields) => {
  return new Promise((resolve, reject) => {
    const url = `${global.API_URL}/${SUB_ROUTE}/private/valid-order`;

    axios
      .post(url, {
        fields,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDeliveryDate = async () => {
  if (!global.API_URL) {
    await fetchRemoteAppConfig();
  }

  const url = `${global.API_URL}/${SUB_ROUTE}/private/delivery-date`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export {
  createOrder,
  getDeliveryDate,
  isValidOrder,
};
