import React from 'react';
import { connect } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { addPaymentSource } from '../../state/actions';

const AddCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const createCard = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, source } = await stripe.createSource(cardElement);

    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      props.addPaymentSource(source);
    }
  };

  return (
    <div id="add-card-container">
      <h4>Add Card:</h4>
      <div id="add-area">
        <div id="cart-element-area">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
        <button type="submit" disabled={!stripe} onClick={createCard}>
          Add Card
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPaymentSource: (token) => {
      dispatch(addPaymentSource(token));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(AddCard);
