/* eslint-disable no-param-reassign */
import axios from 'axios';
import firebase from 'firebase';

const SUB_ROUTE = 'users';

const getUserData = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/${idToken}`;

        axios
          .get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createUser = (userData) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/${idToken}`;

        axios
          .post(url, userData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUserData = (fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/${idToken}`;

        axios
          .put(url, fieldsToUpdate)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const checkIfUserExists = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/${idToken}`;

        axios
          .get(url)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.log(error);

            if (error.response.status === 404 || error.response.status === '404') {
              resolve(false);
            } else {
              reject(error);
            }
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// given a stripe customer token, set the user's payment info
const addPaymentSource = (stripeToken) => {
  return new Promise((resolve, reject) => {
    // generate firebase token for auth
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/add-payment-source/${idToken}`;

        stripeToken.tokenId = stripeToken.id;

        // add card to existing stripe customer object, or create customer
        axios
          .post(url, { token: stripeToken })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// given a stripe customer token, set the user's payment info
const deletePaymentSource = (stripeToken) => {
  return new Promise((resolve, reject) => {
    // generate firebase token for auth
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/delete-payment-source/${idToken}`;

        // create stripe customer and save user payment info
        axios
          .post(url, { token: stripeToken })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// determine if a user with the provided email exists
const userWithEmailExists = (email) => {
  return new Promise((resolve, reject) => {
    const url = `${global.API_URL}/${SUB_ROUTE}/email-exists/${email}`;

    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const requestNewCompany = (email) => {
  return new Promise((resolve, reject) => {
    // generate firebase token for auth
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/new-company/${idToken}`;

        // request new company
        axios
          .post(url, { email })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendPrivateCompanyCode = (code) => {
  return new Promise((resolve, reject) => {
    // generate firebase token for auth
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/new-company-code/${idToken}`;

        // request new company
        axios
          .post(url, { code })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const requestNewFridge = (pin) => {
  return new Promise((resolve, reject) => {
    // generate firebase token for auth
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const url = `${global.API_URL}/${SUB_ROUTE}/new-fridge/${idToken}`;

        // request new company
        axios
          .post(url, { access_pin: pin })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export {
  getUserData,
  createUser,
  updateUserData,
  checkIfUserExists,
  addPaymentSource,
  deletePaymentSource,
  userWithEmailExists,
  requestNewCompany,
  sendPrivateCompanyCode,
  requestNewFridge,
};
