import { ActionTypes } from '../actions';

// initial state
const initialState = {
  products: [],
  productTags: [],

  setProductsInProgress: false,
  setProductTagsInProgress: false,
};

// reducer
const ProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRODUCTS:
      return { ...state, products: action.payload };

    case ActionTypes.SET_PRODUCT_TAGS:
      return { ...state, productTags: action.payload };

    case ActionTypes.SET_PRODUCTS_IN_PROGRESS:
      return { ...state, setProductsInProgress: action.payload };

    case ActionTypes.SET_PRODUCT_TAGS_IN_PROGRESS:
      return { ...state, setProductsInProgress: action.payload };

    default:
      return state;
  }
};

export default ProductsReducer;
