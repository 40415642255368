import axios from 'axios';
import * as firebase from 'firebase';

const SUB_ROUTE = 'fridges';

const getAllFridges = () => {
  return new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          const url = `${global.API_URL}/${SUB_ROUTE}/user/${idToken}`;

          axios
            .get(url)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve([]);
    }
  });
};

const getTrendingItemsAtFridge = (id) => {
  const url = `${global.API_URL}/${SUB_ROUTE}/trending-items/${id}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getAllFridges, getTrendingItemsAtFridge };
