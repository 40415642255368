/* eslint-disable no-param-reassign */
import { Map } from 'immutable';
import { ActionTypes } from '../actions';

const initialState = {
  shoppingCart: Map(),
  numItemsInShoppingCart: 0,
  cartSubtotal: 0,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_SHOPPING_CART:
      return {
        ...state,
        shoppingCart: Map(action.payload),
        cartSubtotal: 0,
        numItemsInShoppingCart: 0,
      };

    case ActionTypes.ADD_TO_SHOPPING_CART:
      if (Map(state.shoppingCart).has(action.payload.id)) {
        action.payload.numInCart += 1;
        return {
          ...state,
          shoppingCart: Map(state.shoppingCart).set(
            action.payload.id,
            action.payload,
          ),
          numItemsInShoppingCart: state.numItemsInShoppingCart + 1,
          cartSubtotal: state.cartSubtotal + action.payload.price,
        };
      } else {
        action.payload.selectedFridge = state.selectedFridge;
        action.payload.numInCart = 1;
        return {
          ...state,
          shoppingCart: Map(state.shoppingCart).set(
            action.payload.id,
            action.payload,
          ),
          numItemsInShoppingCart: state.numItemsInShoppingCart + 1,
          cartSubtotal: state.cartSubtotal + action.payload.price,
        };
      }

    case ActionTypes.SUBTRACT_ITEM_FROM_CART:
      if (Map(state.shoppingCart).has(action.payload.id)) {
        // delete
        if (Map(state.shoppingCart).get(action.payload.id).numInCart <= 1) {
          return {
            ...state,
            shoppingCart: Map(state.shoppingCart).delete(action.payload.id),
            numItemsInShoppingCart: state.numItemsInShoppingCart - 1,
            cartSubtotal: state.cartSubtotal - action.payload.price,
          };
        } else {
          action.payload.numInCart -= 1;
          return {
            ...state,
            shoppingCart: Map(state.shoppingCart).set(
              action.payload.id,
              action.payload,
            ),
            numItemsInShoppingCart: state.numItemsInShoppingCart - 1,
            cartSubtotal: state.cartSubtotal - action.payload.price,
          };
        }
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default CartReducer;
