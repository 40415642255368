// import all actions and action types from various action files
import {
  ActionTypes as appActionTypes,
  fetchRemoteAppConfig,
} from './app-actions';

import {
  ActionTypes as cartActionTypes,
  addToShoppingCart,
  clearShoppingCart,
  subtractItemFromCart,
} from './cart-actions';

import {
  ActionTypes as fridgeActionTypes,
  getAllFridges,
  setSelectedFridge,
} from './fridge-actions';

import {
  ActionTypes as productActionTypes,
  getAllProducts,
  getAllProductTags,
} from './product-actions';

import {
  ActionTypes as promotionActionTypes,
  getAllPromotions,
} from './promotions-actions';

import {
  ActionTypes as userActionTypes,
  getUserData,
  createUser,
  updateUserData,
  clearUserData,
  addPaymentSource,
  deletePaymentSource,
  createUserOrder,
  requestNewCompany,
  sendPrivateCompanyCode,
  requestNewFridge,
} from './user-actions';

// combine all action types
const ActionTypes = {};

Object.keys(appActionTypes).forEach((key) => {
  ActionTypes[key] = appActionTypes[key];
});

Object.keys(cartActionTypes).forEach((key) => {
  ActionTypes[key] = cartActionTypes[key];
});

Object.keys(fridgeActionTypes).forEach((key) => {
  ActionTypes[key] = fridgeActionTypes[key];
});

Object.keys(productActionTypes).forEach((key) => {
  ActionTypes[key] = productActionTypes[key];
});

Object.keys(promotionActionTypes).forEach((key) => {
  ActionTypes[key] = promotionActionTypes[key];
});

Object.keys(userActionTypes).forEach((key) => {
  ActionTypes[key] = userActionTypes[key];
});

// export all action types in one object, as well as each action
export {
  ActionTypes,
  addPaymentSource,
  addToShoppingCart,
  clearShoppingCart,
  clearUserData,
  createUser,
  createUserOrder,
  deletePaymentSource,
  fetchRemoteAppConfig,
  getAllFridges,
  getAllProducts,
  getAllProductTags,
  getAllPromotions,
  getUserData,
  requestNewCompany,
  requestNewFridge,
  sendPrivateCompanyCode,
  setSelectedFridge,
  subtractItemFromCart,
  updateUserData,
};
