import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import Cart from '../cart';
import Receipt from './receipt';
import './style.scss';

const fallbackImage = require('../../assets/images/fallback-product-image.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class Orders extends React.Component {
  closeCart = () => {
    if (this.props.match.params.id && this.props.match.params.id !== 'cart') {
      this.props.history.push(`/account/orders/${this.props.match.params.id}`);
    } else {
      this.props.history.push('/account/orders');
    }
  }

  parseTime = (info) => {
    const date = new Date(info);
    const parseDate = date.toDateString().split(' ');
    const time = date.toLocaleTimeString().split(' ');
    const times = time[0].split(':');

    // month, day, hour, minute, AM/PM
    return [parseDate[1], parseDate[2], times[0], times[1], time[1]];
  }

  renderActiveOrders = () => {
    if (this.props.userData.active_orders && this.props.userData.active_orders.length > 0) {
      // sort newest to oldest
      this.props.userData.active_orders.sort((order1, order2) => {
        return new Date(order2.time_opened) - new Date(order1.time_opened);
      });

      return (
        <div className="orders-region">
          <h3>Active Orders</h3>
          <div className="orders-container">
            {this.props.userData.active_orders.map((order) => {
              // retrieve first product and order date
              const firstProduct = Object.values(order.items)[0].productInfo;
              const date = this.parseTime(order.time_opened);

              return (
                <div className="order-preview"
                  id={this.props.match.params.id === order.id ? 'selected-order' : ''}
                  onClick={() => {
                    if (this.props.match.params.id === undefined || this.props.match.params.id === null || this.props.match.params.id !== order.id) {
                      this.props.history.push(`/account/orders/${order.id}`);
                    } else {
                      this.props.history.push('/account/orders/');
                    }
                  }}
                >
                  <img
                    className="cart-item-product-image"
                    src={`https://drive.google.com/uc?id=${firstProduct.image_id}`}
                    alt={firstProduct.name}
                    onError={(e) => {
                      e.target.src = fallbackImage;
                    }}
                  />

                  <div>
                    <h4>{date[0]}{' '}{date[1]}{' '}{date[2]}:{date[3]}{' '}{date[4]}</h4>
                    <p>${order.total ? order.total.toFixed(2) : 0}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div id="no-orders-region">
          <h3>No Orders</h3>
          <p>Looks like you haven&apos;t ordered anything yet!</p>
          <NavLink to="/">Go To Menu</NavLink>
        </div>
      );
    }
  }

  renderClosedOrders = () => {
    if (this.props.userData.closed_orders && this.props.userData.closed_orders.length > 0) {
      return (
        <div className="orders-region" style={{ marginTop: '20px' }}>
          <h3>Past Orders</h3>
          <div className="orders-container">
            {this.props.userData.closed_orders.map((order) => {
              // retrieve first product and order date
              const firstProduct = Object.values(order.items)[0].productInfo;
              const date = this.parseTime(order.time_opened);

              return (
                <div className="order-preview"
                  id={this.props.match.params.id === order.id ? 'selected-order' : ''}
                  onClick={() => {
                    if (this.props.match.params.id === undefined || this.props.match.params.id === null || this.props.match.params.id !== order.id) {
                      this.props.history.push(`/account/orders/${order.id}`);
                    } else {
                      this.props.history.push('/account/orders/');
                    }
                  }}
                >
                  <img
                    className="cart-item-product-image"
                    src={`https://drive.google.com/uc?id=${firstProduct.image_id}`}
                    alt={firstProduct.name}
                    onError={(e) => {
                      e.target.src = fallbackImage;
                    }}
                  />

                  <div>
                    <h4>{date[0]}{' '}{date[1]}{' '}{date[2]}:{date[3]}{' '}{date[4]}</h4>
                    <p>${order.total ? order.total.toFixed(2) : 0}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const isCartVisible = this.props.location.pathname.endsWith('cart');
    const isReceiptVisible = this.props.match.params.id !== null && this.props.match.params.id !== undefined;

    return (
      <div id="orders">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="orders-content">
          <div id="all-orders-container">
            {this.renderActiveOrders()}
            {this.renderClosedOrders()}
          </div>
          <div>
            <Transition in={isReceiptVisible} timeout={duration}>
              {state => (
                <div style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                >
                  <Receipt order_id={this.props.match.params.id} />
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createFridge: (fields) => {
    //   dispatch(createFridge(fields));
    // },
    // updateFridge: (fields) => {
    //   dispatch(updateFridge(fields));
    // },
    // deleteFridge: (id) => {
    //   dispatch(deleteFridge(id));
    // },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders));
