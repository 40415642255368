const TEST_STRIPE_KEY = 'pk_test_xeV8mrqaBgTCBkbQ8Cf84Dsd';
const PROD_STRIPE_KEY = 'pk_live_lAF5sdZWZPHNzOEvrxP28Cm6';

const REMOTE_APP_CONFIG_ENDPOINT = 'https://remote-app-config-6amhealth.herokuapp.com/order';

const ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  PROD: 'PROD',
  QA: 'QA',
};

const getEnvironment = () => {
  switch (window.location.origin) {
    case 'http://localhost:8080':
      // return ENVIRONMENTS.LOCAL;
      return ENVIRONMENTS.QA;

    case 'https://order-qa.6amhealth.com':
      return ENVIRONMENTS.QA;

    case 'https://order.6amhealth.com':
      return ENVIRONMENTS.PROD;

    default:
      return ENVIRONMENTS.QA;
  }
};

// taken from: https://www.geodatasource.com/developers/javascript
const computeDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2)
              + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist *= 1.609344;
    }
    if (unit === 'N') {
      dist *= 0.8684;
    }
    return dist;
  }
};

export {
  computeDistance,
  getEnvironment,
  PROD_STRIPE_KEY,
  REMOTE_APP_CONFIG_ENDPOINT,
  TEST_STRIPE_KEY,
};
