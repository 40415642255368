import * as productsRequests from '../../services/product-requests';

export const ActionTypes = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_PRODUCT_TAGS: 'SET_PRODUCT_TAGS',

  // the following actions are booleans indicating if a request was sent for each object but not yet received
  SET_PRODUCTS_IN_PROGRESS: 'SET_PRODUCTS_IN_PROGRESS',
  SET_PRODUCT_TAGS_IN_PROGRESS: 'SET_PRODUCT_TAGS_IN_PROGRESS',

  // flag to handle any errors that arise
  API_ERROR: 'API_ERROR',
};

const getAllProducts = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PRODUCTS_IN_PROGRESS, payload: true });
    productsRequests
      .getAllProducts()
      .then((response) => {
        dispatch({ type: ActionTypes.SET_PRODUCTS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_PRODUCTS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_PRODUCTS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const getAllProductTags = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PRODUCT_TAGS_IN_PROGRESS, payload: true });
    productsRequests
      .getAllProductTags(id)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_PRODUCT_TAGS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_PRODUCT_TAGS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_PRODUCT_TAGS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

export {
  getAllProducts,
  getAllProductTags,
};
