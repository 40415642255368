import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import * as firebase from 'firebase';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';

import { addToShoppingCart, subtractItemFromCart } from '../../state/actions';

const fallbackImage = require('../../assets/images/fallback-product-image.png');
const plusImage = require('../../assets/images/plus.png');
const minusImage = require('../../assets/images/minus.png');

const duration = 300;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: 'translateX(400px)',
};

const transitionStyles = {
  entering: { transform: 'translateX(0px)' },
  entered: { transform: 'translateX(0px)' },
  exiting: { transform: 'translateX(400px)' },
  exited: { transform: 'translateX(400px)' },
};

const Cart = (props) => {
  const taxRate = props.selectedFridge.tax_rate || 0.0625;

  return (
    <div>
      <Transition in={props.isOpen} timeout={duration}>
        {state => (
          <div id="cart-popout"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div id="cart-popout-content">
              <div id="top-bar-area">
                <h3>My Cart ({props.numItemsInShoppingCart})</h3>
                <CloseIcon id="close-icon" onClick={props.closeSlideout} />
              </div>
              <div id="cart-items-container">
                {Object.keys(props.cart.toJSON()).map((id) => {
                  const product = props.cart.toJSON()[id];

                  return (
                    <div className="cart-item">
                      <img
                        className="cart-item-product-image"
                        src={`https://drive.google.com/uc?id=${product.image_id}`}
                        alt={product.name}
                        onError={(e) => {
                          e.target.src = fallbackImage;
                        }}
                      />

                      <div className="cart-item-info-area">
                        <h4>{product.name}</h4>
                        <div className="plus-minus-region">
                          <img src={plusImage} alt="plus" onClick={() => props.addToShoppingCart(product)} />
                          <p>{product.numInCart}</p>
                          <img src={minusImage} alt="plus" onClick={() => props.subtractItemFromCart(product)} />
                        </div>
                      </div>

                      <div className="price-area">
                        <h4>{product.price}</h4>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div id="bottom-area">
                <div id="cart-line" />
                <div id="price-region">
                  <p>Subtotal: <span className="horizontal-space" />${props.cartSubtotal.toFixed(2)}</p>
                  <p>Estimated Tax: <span className="horizontal-space" />${(props.cartSubtotal * taxRate).toFixed(2)}</p>
                  <h4>Total: ${(props.cartSubtotal * (1 + taxRate)).toFixed(2)}</h4>
                </div>
                <NavLink to={firebase.auth().currentUser ? '/checkout' : '/login'}>
                  <div id="checkout-button">
                    {firebase.auth().currentUser ? 'Checkout' : 'Sign In To Continue' }
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.shoppingCart,
    numItemsInShoppingCart: state.cart.numItemsInShoppingCart,
    cartSubtotal: state.cart.cartSubtotal,
    selectedFridge: state.fridges.selectedFridge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToShoppingCart: (item) => {
      dispatch(addToShoppingCart(item));
    },
    subtractItemFromCart: (item) => {
      dispatch(subtractItemFromCart(item));
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart));
