/* eslint-disable global-require */
/* eslint-disable no-case-declarations */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group'; // ES6
import './style.scss';

import { setSelectedFridge, fetchRemoteAppConfig } from '../../state/actions';
import { getDeliveryDate } from '../../services/order-requests';

const fallbackImage = require('../../assets/images/fallback-product-image.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryDate: null,
      infoContainerVisible: false,
    };
  }

  async componentDidMount() {
    if (!global.API_URL) {
      await fetchRemoteAppConfig();
    }

    getDeliveryDate()
      .then((date) => {
        this.setState({
          deliveryDate: date,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  selectFridge = (fridge) => {
    if (Object.keys(this.props.selectedFridge).length === 0 || (this.props.selectedFridge.id !== fridge.id)) {
      this.props.setSelectedFridge(fridge);
    } else {
      this.props.setSelectedFridge({});
    }
  }

  renderDeliveryText = () => {
    if (this.state.deliveryDate) {
      switch (this.state.deliveryDate.length) {
        case 0:
          return '';
        case 1:
          let date = new Date(this.state.deliveryDate[0]);
          return `Pickup on ${date.getMonth()}/${date.getDate()}`;
        case 2:
          const date1 = new Date(this.state.deliveryDate[0]);
          const date2 = new Date(this.state.deliveryDate[1]);
          return `Pickup on ${date1.getMonth()}/${date1.getDate()} or ${date2.getMonth()}/${date2.getDate()}`;
        default:
          date = new Date(this.state.deliveryDate[0]);
          return `Pickup as early as ${date.getMonth()}/${date.getDate()}`;
      }
    } else {
      return '';
    }
  }

  renderFridge = (fridge) => {
    if (Object.keys(this.props.selectedFridge).length === 0) {
      return (
        <div
          className="fridge-container"
          onClick={() => { this.selectFridge(fridge); }}
        >
          <div className="name-area">
            <img src={`https://drive.google.com/uc?id=${fridge.image_id}`}
              alt={fridge.name}
              onError={(e) => {
                e.target.src = fallbackImage;
              }}
            />
            <p className="fridge-name">{fridge.name}</p>
          </div>
          <div className="info-container">
            <p className="fridge-location-general">{fridge.location_general}</p>
            <p className="fridge-location-specific">{fridge.location_specific}</p>
          </div>
        </div>
      );
    } else if (this.props.selectedFridge.id !== fridge.id) {
      return (
        <div
          className="fridge-container fanned-out"
          onClick={() => { this.selectFridge(fridge); }}
        >
          <div className="name-area">
            <img src={`https://drive.google.com/uc?id=${fridge.image_id}`}
              alt={fridge.name}
              onError={(e) => {
                e.target.src = fallbackImage;
              }}
            />
            <p className="fridge-name">{fridge.name}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="fridge-container fridge-selected"
          onClick={() => { this.selectFridge(fridge); }}
        >
          <div className="name-area">
            <img src={`https://drive.google.com/uc?id=${fridge.image_id}`}
              alt={fridge.name}
              onError={(e) => {
                e.target.src = fallbackImage;
              }}
            />
            <p className="fridge-name">{fridge.name}</p>
          </div>
          <div className="info-container">
            <p className="fridge-location-general">{fridge.location_general}</p>
            <p className="fridge-location-specific">{fridge.location_specific}</p>
            <p className="fridge-specific-instructions">{fridge.specific_instructions}</p>
            <div className="fridge-delivery-area">
              <img src={require('../../assets/images/question-mark.png')}
                alt="question-mark"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState(prevState => ({
                    infoContainerVisible: !prevState.infoContainerVisible,
                  }));
                }}
              />
              <p className="fridge-delivery-date">{this.renderDeliveryText()}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  renderAllFridges = () => {
    if (Object.keys(this.props.selectedFridge).length === 0) {
      return this.props.fridges.sort((a, b) => ((a.name > b.name) ? 1 : -1)).map(fridge => this.renderFridge(fridge));
    } else {
      return (
        <div>
          {this.renderFridge(this.props.selectedFridge)}
          <div style={{ marginTop: 20 }}>
            {this.props.fridges.filter(fridge => fridge.id !== this.props.selectedFridge.id)
              .sort((a, b) => ((a.name > b.name) ? 1 : -1))
              .map(fridge => this.renderFridge(fridge))}
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.props.fridges.length === 1 && Object.keys(this.props.selectedFridge).length === 0) {
      this.selectFridge(this.props.fridges[0]);
    }

    return (
      <div id="fridge-container">
        <div id="fridges">
          {this.renderAllFridges()}
        </div>
        <div>
          <Transition in={this.state.infoContainerVisible || this.props.infoContainerVisible} timeout={duration}>
            {state => (
              <div style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              >
                <div id="fridge-info-container">
                  <h4>When do I get my food?</h4>
                  <p>We deliver every Monday morning. If you order by Thursday at 5pm, we&apos;ll deliver as early as the next Monday.
                      Otherwise, the Monday after that will be the first available delivery date.
                  </p>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fridges: state.fridges.fridges,
    selectedFridge: state.fridges.selectedFridge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedFridge: (fridge) => {
      dispatch(setSelectedFridge(fridge));
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart));
