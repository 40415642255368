import axios from 'axios';

const SUB_ROUTE = 'promotions';

const getAllPromotions = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${global.API_URL}/${SUB_ROUTE}/current`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// eslint-disable-next-line import/prefer-default-export
export { getAllPromotions };
