import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import * as firebase from 'firebase';
import Cart from '../../cart';
import '../sign-up/style.scss';

import { getUserData } from '../../../state/actions';

const googleImage = require('../../../assets/images/google.png');
const facebookImage = require('../../../assets/images/facebook-white.png');
const appleImage = require('../../../assets/images/apple.png');

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  closeCart = () => {
    this.props.history.push('/login');
  }

  signIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(
        () => {
          this.props.getUserData(firebase.auth().currentUser.uid);
          this.props.history.push('/');
        },
      )
      .catch((error) => {
        console.log(error);
      });
  }

  signInGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  signInFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  signInApple = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    firebase.auth().signInWithPopup(provider).then((result) => {
      this.props.getUserData(firebase.auth().currentUser.uid);
      this.props.history.push('/');
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const isCartVisible = this.props.location.pathname === '/login/cart';

    return (
      <div id="sign-up">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div id="welcome-area">
          <h2>Welcome to 6AM Health</h2>
          <p>Don&apos;t have an account? <span onClick={() => { this.props.history.push('/sign-up'); }}>Click here</span> to sign up.</p>
        </div>

        <div id="top-section">
          <div id="sign-up-area">
            <div className="input-area">
              <p>Email</p>
              <input value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
            </div>
          </div>
          <div id="sign-up-area">
            <div className="input-area">
              <p>Password</p>
              <input value={this.state.password} onChange={e => this.setState({ password: e.target.value })} type="password" />
            </div>
          </div>

          <div id="sign-up-button" onClick={this.signIn} style={{ textAlign: 'center' }}>
                Sign In
          </div>

          <div id="line-area">
            <div className="line" />
            <p>OR</p>
            <div className="line" />
          </div>

          <div className="third-party-area" onClick={this.signInGoogle}>
            <img src={googleImage} alt="google-logo" />
            <span>Sign in with Google</span>
          </div>

          <div className="third-party-area" style={{ backgroundColor: '#3B5998' }} onClick={this.signInFacebook}>
            <img src={facebookImage} alt="facebook-logo" />
            <span style={{ color: 'white' }}>Sign in with Facebook</span>
          </div>

          <div className="third-party-area" style={{ backgroundColor: 'black' }} onClick={this.signInApple}>
            <img id="apple-image" src={appleImage} alt="apple-logo" />
            <span style={{ color: 'white' }}>Sign in with Apple</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (id) => {
      dispatch(getUserData(id));
    },
  };
};

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(SignIn));
