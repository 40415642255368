import * as promotionRequests from '../../services/promotions-requests';

export const ActionTypes = {
  SET_ALL_PROMOTIONS: 'SET_ALL_PROMOTIONS',

  // the following actions are booleans indicating if a request was sent for each object but not yet received
  SET_ALL_PROMOTIONS_IN_PROGRESS: 'SET_ALL_PROMOTIONS_IN_PROGRESS',

  // flag to handle any errors that arise
  API_ERROR: 'API_ERROR',
};

const getAllPromotions = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ALL_PROMOTIONS_IN_PROGRESS, payload: true });
    promotionRequests
      .getAllPromotions()
      .then((response) => {
        dispatch({ type: ActionTypes.SET_ALL_PROMOTIONS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_ALL_PROMOTIONS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_ALL_PROMOTIONS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

export { getAllPromotions };
