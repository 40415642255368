import { ActionTypes } from '../actions';

// initial state
const initialState = {
  promotions: [],
  setPromotionsInProgress: false,
};

// reducer
const PromotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_PROMOTIONS:
      return { ...state, promotions: action.payload };

    case ActionTypes.SET_ALL_PROMOTIONS_IN_PROGRESS:
      return { ...state, setPromotionsInProgress: action.payload };

    default:
      return state;
  }
};

export default PromotionsReducer;
