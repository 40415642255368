/* eslint-disable no-param-reassign */
import { ActionTypes } from '../actions';

// initial state
const initialState = {
  fridges: [],
  selectedFridge: {},
  trendingItems: [],

  setFridgesInProgress: false,
  setTrendingItemsInProgress: false,
};

// reducer
const FridgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_FRIDGES:
      return { ...state, fridges: action.payload };

    case ActionTypes.SET_SELECTED_FRIDGE:
      return { ...state, selectedFridge: action.payload, fridgeInventory: {} };

    case ActionTypes.SET_TRENDING_ITEMS:
      return { ...state, trendingItems: action.payload };

    case ActionTypes.SET_ALL_FRIDGES_IN_PROGRESS:
      return { ...state, setFridgesInProgress: action.payload };

    case ActionTypes.SET_TRENDING_ITEMS_IN_PROGRESS:
      return { ...state, setTrendingItemsInProgress: action.payload };

    default:
      return state;
  }
};

export default FridgeReducer;
