import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import Cart from '../cart';
import './style.scss';

import {
  requestNewCompany, sendPrivateCompanyCode, requestNewFridge, getAllFridges,
} from '../../state/actions';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class AddFridge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      infoContainerVisible: false,
      infoContainerHeader: '',
      infoContainerText: '',
      corporateEmail: '',
      corporateEmailPIN: '',
      fridgePIN: '',
    };
  }

  closeCart = () => {
    this.props.history.push('/account/add-fridge');
  }

  sendMessage = (header, text) => {
    this.setState({
      infoContainerVisible: true,
      infoContainerHeader: header,
      infoContainerText: text,
    }, () => {
      setTimeout(() => {
        this.setState({
          infoContainerVisible: false,
          infoContainerHeader: '',
          infoContainerText: '',
        });
      }, 5000);
    });
  }

  addCorporateEmail = () => {
    if (this.state.corporateEmail.length > 0) {
      this.props.requestNewCompany(this.state.corporateEmail);
    } else {
      this.sendMessage('Uh oh!', 'Looks like you entered an empty email.');
    }
  }

  addCorporateEmailPIN = () => {
    if (this.state.corporateEmailPIN.length > 0) {
      this.props.sendPrivateCompanyCode(this.state.corporateEmailPIN, () => {
        this.props.getAllFridges();
        this.sendMessage('Success!', 'Thanks for adding your corporate email. You will see the list of available fridges when you go to checkout.');
      }, (error) => {
        if (error.response.data && error.response.data.includes('did not match')) {
          this.sendMessage('Incorrect Code', 'You entered the incorrect code. Please check your email for the correct code, then try again.');
        } else {
          this.sendMessage('Uh oh!', 'We encountered an error processing your code. Please try again.');
        }
      });
    } else {
      this.sendMessage('Uh oh!', 'Looks like you entered an empty code.');
    }
  }

  addFridgePIN = () => {
    if (this.state.fridgePIN.length > 0) {
      this.props.requestNewFridge(this.state.fridgePIN, () => {
        this.props.getAllFridges();
        this.sendMessage('Success!', 'You now have access to this fridge. You will see the list of available fridges when you go to checkout.');
      }, (error) => {
        if (error.response.statusText && error.response.statusText.includes('Not Found')) {
          this.sendMessage('No Fridge for this PIN', 'We couldn\'t find a fridge matching that PIN. Please check the front of the fridge for the correct code, then try again.');
        } else {
          this.sendMessage('Uh oh!', 'We encountered an error processing your code. Please try again.');
        }
      });
    } else {
      this.sendMessage('Uh oh!', 'Looks like you entered an empty code.');
    }
  }


  renderCorporateEmailField = () => {
    if (this.props.userData.private_emails_to_verify) {
      if (Object.keys(this.props.userData.private_emails_to_verify).length > 0) {
        return null;
      }
    }

    return (
      <div id="corporate-email-field" className="access-input-container">
        <h3>Corporate Email Address</h3>
        <p>Get access to all fridges at your company.</p>
        <div className="input-area">
          <input
            value={this.state.corporateEmail}
            onChange={e => this.setState({ corporateEmail: e.target.value })}
            placeholder="example@domain.com"
          />

          <div className="submit-button" onClick={this.addCorporateEmail}>
                Submit
          </div>
        </div>
      </div>
    );
  }

  renderPrivateCompanyCodeInput = () => {
    if (!this.props.userData || !this.props.userData.private_emails_to_verify) {
      return null;
    }

    return Object.keys(this.props.userData.private_emails_to_verify).map((key) => {
      return (
        <div id="corporate-email-pin-field" className="access-input-container">
          <h3>Enter Verification Code</h3>
          <p>{`We sent a code to ${key}.`}</p>
          <div className="input-area">
            <input
              value={this.state.corporateEmailPIN}
              onChange={e => this.setState({ corporateEmailPIN: e.target.value })}
              placeholder="0000"
              maxLength={4}
              type="number"
            />

            <div className="submit-button" onClick={this.addCorporateEmailPIN}>
                Submit
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const isCartVisible = this.props.location.pathname === '/account/add-fridge/cart';

    return (
      <div id="add-fridge">
        <Cart
          isOpen={isCartVisible}
          closeSlideout={this.closeCart}
        />

        <Transition in={isCartVisible} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <div id={isCartVisible ? 'cart-negative-click-region' : ''} onClick={this.closeCart} />
            </div>
          )}
        </Transition>

        <div>
          <Transition in={this.state.infoContainerVisible} timeout={duration}>
            {state => (
              <div style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              >
                <div id="alert-info-container">
                  <h4>{this.state.infoContainerHeader}</h4>
                  <p>{this.state.infoContainerText}</p>
                </div>
              </div>
            )}
          </Transition>
        </div>

        <div id="add-fridge-content">
          <div id="description-area">
            <h3>Add Private Fridge</h3>
            <p>There are two ways to add a fridge to your account:</p>
            <ol>
              <li>If you are a member of a company that works with 6AM Health, type your corporate email address in the box below. We will then send a
                4-digit code to your email. Input it here, and you will have access to all the fridges at your company.
              </li>
              <li>On the front of every fridge should be a 4-digit code. Input it in the other box below and you will have access to just that fridge.</li>
            </ol>
          </div>

          <div id="add-fridge-content-container">
            <div className="separator-line" />

            {this.renderCorporateEmailField()}
            {this.renderPrivateCompanyCodeInput()}

            <div id="or-separator">
              <div className="or-line" />
              <p>OR</p>
              <div className="or-line" />
            </div>

            <div id="fridge-pin-field" className="access-input-container">
              <h3>Fridge PIN</h3>
              <p>Get access to a specific fridge.</p>
              <div className="input-area">
                <input
                  value={this.state.fridgePIN}
                  onChange={e => this.setState({ fridgePIN: e.target.value })}
                  placeholder="0000"
                  maxLength={4}
                  type="number"
                />

                <div className="submit-button" onClick={this.addFridgePIN}>
                Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestNewCompany: (email) => {
      dispatch(requestNewCompany(email));
    },
    sendPrivateCompanyCode: (code, successCallback, errorCallback) => {
      dispatch(sendPrivateCompanyCode(code, successCallback, errorCallback));
    },
    requestNewFridge: (pin, successCallback, errorCallback) => {
      dispatch(requestNewFridge(pin, successCallback, errorCallback));
    },
    getAllFridges: () => {
      dispatch(getAllFridges());
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddFridge));
