import React from 'react';
import Rodal from 'rodal';
import { connect } from 'react-redux';
import 'rodal/lib/rodal.css';

import { addToShoppingCart, updateUserData } from '../../state/actions';

const fallbackImage = require('../../assets/images/fallback-product-image.png');
const notFavoritedImage = require('../../assets/icons/heart-unfilled.png');
const favoritedImage = require('../../assets/icons/heart-filled.png');

const ItemPopup = (props) => {
  const addToCart = () => {
    props.addToShoppingCart(props.product);
    props.closeModal();
  };

  const favorite = props.product && props.userData && props.userData.favorite_item_ids && props.userData.favorite_item_ids.includes(props.product.id);

  const handleUserFavorite = () => {
    if (favorite) {
      let favoriteItems = props.userData.favorite_item_ids;
      favoriteItems = favoriteItems.filter(id => id !== props.product.id);
      props.updateUserData({ favorite_item_ids: favoriteItems });
    } else {
      const favoriteItems = props.userData.favorite_item_ids;
      favoriteItems.push(props.product.id);
      props.updateUserData({ favorite_item_ids: favoriteItems });
    }
  };

  return (
    <Rodal
      visible={props.isOpen}
      onClose={props.closeModal}
      width="500"
      height="550"
      customStyles={{ overflowY: 'scroll' }}
      closeOnEsc
    >
      {props.product ? (
        <div id="modal-content">
          {Object.keys(props.userData).length > 0 ? (
            <img src={favorite ? favoritedImage : notFavoritedImage} id="fav-img" alt={favorite ? 'heart filled' : 'heart unfilled'} onClick={handleUserFavorite} />
          ) : null}

          <img src={`https://drive.google.com/uc?id=${props.product.image_id}`}
            alt={props.product.name}
            onError={(e) => {
              e.target.src = fallbackImage;
            }}
          />
          <div className="text-area">
            <h2>{props.product.name}</h2>
            {props.product.description ? <p className="description">{props.product.description}</p> : null}
            <div className="green-line" />
            <p>{`$${props.product.price.toFixed(2)}`}</p>
            {(props.product.gluten_free || props.product.vegan || props.product.vegetarian) ? (
              <div className="food-tag-container">
                {props.product.gluten_free ? <div className="food-tag">GLUTEN FREE</div> : null}
                {props.product.vegan ? <div className="food-tag">VEGAN</div> : null}
                {props.product.vegetarian ? <div className="food-tag">VEGETARIAN</div> : null}
              </div>
            ) : null}
            <div className="ingredients">
              <h4>Ingredients:</h4>
              <p>{props.product.ingredients.join(', ')}</p>
            </div>
            <div className="nutrient-info">
              <h4>Macro-Nutrient Information:</h4>
              {Object.keys(props.product.nutrition_facts).map((fact) => {
                return (
                  <div className="nutrient-info-line">
                    <p>{fact}</p>
                    <p>{props.product.nutrition_facts[fact]}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="add-cart-button" onClick={addToCart}>
            Add to Cart
          </div>
          <div className="gradient" />
        </div>
      ) : <p id="product-not-found">Uh oh! We couldn&apos;t find that product.</p>}
    </Rodal>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToShoppingCart: (item) => {
      dispatch(addToShoppingCart(item));
    },
    updateUserData: (fields) => {
      dispatch(updateUserData(fields));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemPopup);
