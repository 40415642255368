import * as fridgeRequests from '../../services/fridge-requests';

export const ActionTypes = {
  SET_ALL_FRIDGES: 'SET_ALL_FRIDGES',
  SET_SELECTED_FRIDGE: 'SET_SELECTED_FRIDGE',
  SET_TRENDING_ITEMS: 'SET_TRENDING_ITEMS',

  // the following actions are booleans indicating if a request was sent for each object but not yet received
  SET_ALL_FRIDGES_IN_PROGRESS: 'SET_ALL_FRIDGES_IN_PROGRESS',
  SET_TRENDING_ITEMS_IN_PROGRESS: 'SET_TRENDING_ITEMS_IN_PROGRESS',

  // flag to handle any errors that arise
  API_ERROR: 'API_ERROR',
};

const getAllFridges = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_ALL_FRIDGES_IN_PROGRESS, payload: true });
    fridgeRequests
      .getAllFridges()
      .then((response) => {
        dispatch({ type: ActionTypes.SET_ALL_FRIDGES_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_ALL_FRIDGES, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_ALL_FRIDGES_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

const setSelectedFridge = (fridgeData) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_SELECTED_FRIDGE, payload: fridgeData });
    dispatch({ type: ActionTypes.SET_TRENDING_ITEMS_IN_PROGRESS, payload: true });
    fridgeRequests
      .getTrendingItemsAtFridge(fridgeData.id)
      .then((response) => {
        dispatch({ type: ActionTypes.SET_TRENDING_ITEMS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.SET_TRENDING_ITEMS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_TRENDING_ITEMS_IN_PROGRESS, payload: false });
        dispatch({ type: ActionTypes.API_ERROR, payload: error });
      });
  };
};

export {
  getAllFridges, setSelectedFridge,
};
