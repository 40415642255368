import React from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import * as firebase from 'firebase';
import {
  fetchRemoteAppConfig,
  getAllFridges,
  getAllProducts,
  getAllProductTags,
  getAllPromotions,
  getUserData,
} from './state/actions';

import Menu from './routes/menu';
import SignIn from './routes/auth/sign-in';
import SignUp from './routes/auth/sign-up';
import NavBar from './components/nav-bar';
import Checkout from './routes/checkout';
import HowItWorks from './routes/how-it-works';
import Account from './routes/account';
import Orders from './routes/account/orders';
import OrderSummary from './routes/account/order-summary';
import AddFridge from './routes/account/add-fridge';

const FallBack = () => {
  return <div>URL not found</div>;
};

class App extends React.Component {
  async componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      await this.props.fetchRemoteAppConfig();

      // load user data
      if (user) {
        this.props.getUserData();
      }

      this.props.getAllFridges();
    });

    await this.props.fetchRemoteAppConfig();

    // load data
    this.props.getAllFridges();
    this.props.getAllProducts();
    this.props.getAllProductTags();
    this.props.getAllPromotions();
  }

  render() {
    return (
      <Router>
        <div>
          <NavBar />
          <Switch>
            <Route exact path="/" component={Menu} />
            <Route path="/login" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/product/:id" component={Menu} />
            <Route path="/cart" component={Menu} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/how-it-works" component={HowItWorks} />
            <Route path="/account/add-fridge" component={AddFridge} />
            <Route path="/account/orders/summary/:id" component={OrderSummary} />
            <Route path="/account/orders/:id" component={Orders} />
            <Route path="/account/orders" component={Orders} />
            <Route path="/account" component={Account} />
            <Route component={FallBack} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default connect(null, {
  fetchRemoteAppConfig,
  getAllFridges,
  getAllProducts,
  getAllProductTags,
  getAllPromotions,
  getUserData,
})(App);
